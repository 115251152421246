import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import Icon from "components/Icon";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Location.scss";

const Location = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader } = frontmatter;

  return (
    <PageSection className={clsx("portfolio-section", className)} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        <Col sm={1} className="mx-auto text-center"/>
        <Col sm={10} className="mx-auto text-center">
          <article className="article">
            <div className="card">
              <div className="thumb"> 
                <div className="infos text-center"> 
                  <h2 className="title">The Cove Lakeside Resort</h2>
                  <h3 className="date">West Kelowna, BC</h3>
                  <div className="up-arrow text-center"><Icon iconName="SortUpIcon" size="1x" className="mb-3" /></div>
                  <h3 className="seats">4205 Gellatly Rd, West Kelowna, BC V4T 2K2</h3>
                  <p className="txt">
                    We would be delighted if you could join us at the cove for our special day.<br/>
                    While there is no obligation to stay at the resort, rooms are available at a reduced rate.<br/>
                    Reach out to us if you want details.
                  </p>
                  <a href="https://www.covelakeside.com/" target="_blank" rel="noopener noreferrer"><h3 className="details">Read More</h3></a>
                </div>
              </div>
            </div>
          </article>
        </Col>
      <Col sm={1} className="mx-auto c"/>
      </Row>
    </PageSection>
  );
};

Location.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Location.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Location;
