import React from "react";
import PropTypes from "prop-types";

import { Button, Container, Card } from "react-bootstrap";
import useSmoothScrollTo from "hooks/useSmoothScrollTo";

import Image from "components/Image";
import "./Top.scss";

const Top = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { header, subheader, imageFileName, jumpToAnchor, jumpToAnchorText } = frontmatter;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scrollToSection = useSmoothScrollTo(jumpToAnchor);

  let extraInfo;
  if (jumpToAnchor && jumpToAnchorText) {
    extraInfo = (
      <Button size="xl" variant="primary" className="btn-lead text-uppercase" onClick={scrollToSection}>
        {jumpToAnchorText}
      </Button>
    );
  }

  return (
    <Card className="top-card bg-dark text-white text-center">
      <Image className="image" fileName={imageFileName} alt={ header || subheader } />
      <Card.ImgOverlay className="no-padding">
        <Container>
          <div className="intro-text">
            <div className="intro-heading">{header}</div>
            <div className="intro-lead-in">{subheader}</div>
            {extraInfo}
          </div>
        </Container>
      </Card.ImgOverlay>
    </Card>
  );
};

Top.propTypes = {
  frontmatter: PropTypes.object,
};

Top.defaultProps = {
  frontmatter: null,
};

export default Top;
