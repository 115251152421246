import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import Image from "components/Image";

import "./Message.scss";

const Message = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, coupleMember } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} />
      </Row>
      <Row>
        <Col md={1} lg={2}/>
        {coupleMember.map(({ header, imageFileName }) => (
          <Col md={5} lg={4} key={header}>
            <div className="couple-member">
              <Image
                className="mx-auto image-couple rounded-circle"
                fileName={imageFileName}
                alt={header}
              />
              <h4>{header}</h4>
            </div>
          </Col>
        ))}
        <Col md={2}/>
      </Row>
    </PageSection>
  );
};

Message.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Message.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Message;
