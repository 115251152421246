import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import TeamMember from "components/TeamMember";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Registry.scss";

const Registry = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    registryItem
  } = frontmatter;

  return (
    <div>
      <PageSection className={clsx(className, "registry")} id={anchor}>
        <Row>
          <SectionHeader header={rootHeader} subheader={rootSubHeader} />
        </Row>
        <Row className="charities">
          <Col lg={8} className="mx-auto text-center">
            <p className="large text-muted">
              All we want is your company and the memories the day brings.<br/>
              In lieu of a gift, we would love for you to consider donating to one of the following charities.</p>
          </Col>
        </Row>
        <Row>
          {registryItem.map(({ header, ...tmProps }) => (
            <Col sm={6} md={6} lg={6} xl={3} key={header}>
              <TeamMember header={header} {...tmProps} />
            </Col>
          ))}
        </Row>
      </PageSection>
    </div>
  );
};

Registry.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Registry.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Registry;