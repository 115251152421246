import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Date.scss";

const TheDate = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader } = frontmatter;

  return (
    <PageSection className={clsx("portfolio-section", className)} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        <div className="container">
          <div className="calendar-container">
            <header>
              <div className="day">17</div>
              <div className="month">September</div>
              <div className="year">2022</div>
            </header>
            <table className="calendar">
              <thead>
                <tr>
                  <td>Mon</td>
                  <td>Tue</td>
                  <td>Wed</td>
                  <td>Thu</td>
                  <td>Fri</td>
                  <td>Sat</td>
                  <td>Sun</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="prev-month">29</td>
                  <td className="prev-month">30</td>
                  <td className="prev-month">31</td>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>6</td>
                  <td>7</td>
                  <td>8</td>
                  <td>9</td>
                  <td>10</td>
                  <td>11</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>13</td>
                  <td>14</td>
                  <td>15</td>
                  <td>16</td>
                  <td className="current-day">17</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>19</td>
                  <td>20</td>
                  <td>21</td>
                  <td>22</td>
                  <td>23</td>
                  <td>24</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>26</td>
                  <td>27</td>
                  <td>28</td>
                  <td>29</td>
                  <td>30</td>
                  <td className="next-month">1</td>
                  <td className="next-month">2</td>
                </tr>
              </tbody>
            </table>
            <div className="ring-left"/>
            <div className="ring-right"/>
          </div>
        </div>
      </Row>
    </PageSection>
  );
};

TheDate.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

TheDate.defaultProps = {
  className: null,
  frontmatter: null,
};

export default TheDate;
